<template>
  <div class="matchCo">
    <Header
      back="返回"
      title="知识产权"
      index="首页"
      titleOne="科创模块"
      titleTwo="知识产权"
      titleThree="专利列表"
      beforeTitle="新增/编辑"
    />
    <div class="content">
      <h1>专利信息</h1>
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="专利名称" prop="patent_name">
          <el-input v-model="form.patent_name" placeholder="请输入专利名称"></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            class="inline-input"
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            placeholder="请输入企业名称关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="专利类型" prop="patent_type">
          <el-select v-model="form.patent_type" placeholder="请选择活动区域">
            <el-option label="发明专利" :value="1">发明专利</el-option>
            <el-option label="实用新型" :value="2">实用新型</el-option>
            <el-option label="外观设计" :value="3">外观设计</el-option>
            <el-option label="植物新品种" :value="4">植物新品种</el-option>
            <el-option label="国家新药" :value="5">国家新药</el-option>
            <el-option label="集成电路布图设计专有权" :value="6">集成电路布图设计专有权</el-option>
            <el-option label="国家级农作物品种" :value="7">国家级农作物品种</el-option>
            <el-option label="国家一级中药保护品种" :value="8">国家一级中药保护品种</el-option>
            <el-option label="其中：国防专利" :value="9">其中：国防专利</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专利状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择专利状态">
            <el-option label="新申请" :value="1">新申请</el-option>
            <el-option label="审查中" :value="2">审查中</el-option>
            <el-option label="审查合格" :value="3">审查合格</el-option>
            <el-option label="发明实审" :value="4">发明实审</el-option>
            <el-option label="授权" :value="5">授权</el-option>
            <el-option label="驳回" :value="6">驳回</el-option>
            <el-option label="撤回" :value="7">撤回</el-option>
            <el-option label="专利权维持" :value="8">专利权维持</el-option>
            <el-option label="专利权失效" :value="9">专利权失效</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请号" prop="app_code">
          <el-input v-model="form.app_code"></el-input>
        </el-form-item>
        <el-form-item label="公开号">
          <el-input v-model="form.public_code"></el-input>
        </el-form-item>
        <el-form-item label="申请日期" prop="app_date">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.app_date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="公开日期">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.public_date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="分类号">
          <el-input v-model="form.classify_code"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="代理机构">
          <el-input v-model="form.organ"></el-input>
        </el-form-item>
        <el-form-item label="代理人">
          <el-input v-model="form.agent"></el-input>
        </el-form-item>
        <el-form-item label="工程师">
          <el-input v-model="form.engineer"></el-input>
        </el-form-item>
        <el-form-item label="流程人">
          <el-input v-model="form.process"></el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="business_type">
          <el-input v-model="form.business_type"></el-input>
        </el-form-item>
        <el-form-item label="下证情况">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.certificate_date"
            style="width: 100%"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="获得方式" prop="get_type">
          <el-select v-model="form.get_type" placeholder="请选择获得方式">
            <el-option label="自主研发" :value="1">自主研发</el-option>
            <el-option label="转让" :value="2">转让</el-option>
            <el-option label="授赠" :value="3">授赠</el-option>
            <el-option label="并购" :value="4">并购</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定RD">
          <el-autocomplete
            class="inline-input"
            v-model="form.rd_name"
            :fetch-suggestions="fuzzySearch"
            @select="fuzzySelect"
            placeholder="RD关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="绑定PS">
          <el-autocomplete
            class="inline-input"
            v-model="form.ps_name"
            :fetch-suggestions="fuzzySearched"
            @select="fuzzySelected"
            placeholder="PS关键字筛选"
          ></el-autocomplete>
        </el-form-item>
        <div class="clear"></div>
        <el-form-item label="摘要" style="width: 100%">
          <el-input type="textarea" v-model="form.desc" :autosize="{ minRows: 5, maxRows: 5 }"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%">
          <div class="foot_TJ">
            <el-button
              type="primary"
              :loading="btnLoading"
              @click="onSubmit()"
              :disabled="isDisable"
            >确定</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      isDisable: false,
      labelPosition: "top",
      form: {
        patent_name: "",
        company_name: "",
        patent_type: "",
        status: "",
        app_code: "",
        public_code: "",
        app_date: "",
        public_date: "",
        classify_code: "",
        address: "",
        organ: "",
        agent: "",
        engineer: "",
        process: "",
        business_type: "",
        certificate_date: "",
        get_type: "",
        desc: "",
        rd_name: "",
        rd_id: "",
        com_id: "",
        ps_name: "",
        ps_id: "",
      },
      formed: {},
      formRule: {
        patent_name: [
          {
            required: true,
            message: "请输入专利名称",
            trigger: "blur",
          },
        ],
        company_name: [
          {
            required: true,
            message: "请输入企业名称关键字",
            trigger: "blur",
          },
        ],
        patent_type: [
          {
            required: true,
            message: "请选择专利类型",
            trigger: "change",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
        app_code: [
          {
            required: true,
            message: "请输入申请号",
            trigger: "blur",
          },
        ],
        app_date: [
          {
            required: true,
            message: "请选择申请日期",
            trigger: "change",
          },
        ],
        business_type: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: "change",
          },
        ],
        get_type: [
          {
            required: true,
            message: "请选择获得方式",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/patent/view", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
  methods: {
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    // rd接口
    fuzzySearch (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/rd/select_rd_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.rd_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    // ps接口
    fuzzySearched (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/ps/select_ps_list", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.ps_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    fuzzySelect (item) {
      this.form.rd_name = item.value;
      this.form.rd_id = item.id;
    },
    fuzzySelected (item) {
      this.form.ps_name = item.value;
      this.form.ps_id = item.id;
    },
    onSubmit () {
      this.$refs.formRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/patent/store", this.form)
            .then((res) => {
              console.log(res);
              this.$router.go(-1);
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.isDisable = true;
              this.btnLoading = false
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        }
      });
    },
  },
};
</script>

<style>
.matchCo .el-form-item__label {
  width: 100% !important;
  text-align: left;
}

.matchCo .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.matchCo .el-select {
  width: 100%;
}

.matchCo .el-checkbox {
  float: left;
  color: #909399;
}
.matchCo ul li {
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.matchCo ul li span {
  color: #909399;
}
.matchCo .el-radio-group {
  float: right;
}
.matchCo h5 {
  font-size: 16px;
  color: #cbcfe3;
  font-weight: 400;
  border-bottom: 1px solid #edeef4;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
</style>
